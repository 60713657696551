import React, { useState } from 'react';
import { useGetPartsZipComponentDetailsMutation, useGetPdfLinkForConversationsMutation } from "@circuitry-ai/doc-data";
import { PartsSvgViewer } from "./partssvgviewer";
import { Icon } from '@iconify/react';
import CtySpinner from '../spinner/CtySpinner';

export interface ComponentNode {
    name: string;
    path: string;
}

export interface ComponentGroup {
    component_name: string;
    components: ComponentNode[];
}

export interface ComponentDetails {
    component_name: string;
    referenceNumber: string;
    parts_number: string;
    name: string;
    quantity: string;
}

export interface ZipExplorerProps {
    hierarchyData: ComponentGroup[];
    svgUrl: string;
    sourceIdData: any;
    advisorId?: string;
}

// Helper function to determine file type
const getFileType = (fileName: string): 'pdf' | 'svg' | 'image' | 'other' => {
    if (!fileName) return 'other';
    const extension = fileName.toLowerCase().split('.').pop() || '';
    if (extension === 'pdf') return 'pdf';
    if (extension === 'svg') return 'svg';
    if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) return 'image';
    return 'other';
};

function ComponentDetailsPanel({ details, onClose, setActiveComponentDetails }: { details?: [ComponentDetails]; onClose: () => void, setActiveComponentDetails: (detail: ComponentDetails) => void }) {
    if (!details) return null;

    return (
        <div className="w-[600px] flex-shrink-0 border-l border-gray-200 bg-white overflow-auto">
            <div className="flex justify-between items-center p-4 border-b border-gray-200">
                <h2 className="font-bold">Component Details</h2>
                <button onClick={onClose} className="p-1">
                    <Icon icon="lets-icons:expand-right" width="24" height="24" style={{ color: "#000" }} />
                </button>
            </div>
            <div className="p-4">
                <table className="w-full border-collapse">
                    <thead>
                        <tr className="bg-[#f8f9fa]">
                            <th className="border border-gray-200 p-2 text-left">Ref</th>
                            <th className="border border-gray-200 p-2 text-left">Part Number</th>
                            <th className="border border-gray-200 p-2 text-left">Part Name</th>
                            <th className="border border-gray-200 p-2 text-left">Quantity</th>
                        </tr>
                    </thead>
                    {
                        details.map((detail) => (
                            <tbody>
                                <tr onClick={() => setActiveComponentDetails(detail)}>
                                    <td className="border border-gray-200 p-2">{detail.referenceNumber}</td>
                                    <td className="border border-gray-200 p-2">{detail.parts_number}</td>
                                    <td className="border border-gray-200 p-2">{detail.name}</td>
                                    <td className="border border-gray-200 p-2">{detail.quantity}</td>
                                </tr>
                            </tbody>
                        ))
                    }
                </table>
            </div>
        </div>
    );
}

function ZipFileExplorer({
    hierarchyData,
    svgUrl,
    sourceIdData,
    advisorId,
}: ZipExplorerProps) {
    // This state holds the currently active file's information
    const [activeFile, setActiveFile] = useState<{
        url: string;
        type: 'pdf' | 'svg' | 'image' | 'other';
        name: string;
        details?: [ComponentDetails];
    }>({
        url: svgUrl,
        type: getFileType(svgUrl),
        name: ''
    });

    // Left panel open/close state.
    const [leftPanelOpen, setLeftPanelOpen] = useState(true);
    const [rightPanelOpen, setRightPanelOpen] = useState(true);
    const [getPdfLink] = useGetPdfLinkForConversationsMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [getZipComponents] = useGetPartsZipComponentDetailsMutation();
    const [activeComponentDetails, setActiveComponentDetails] = useState<ComponentDetails | null>(null);
    console.log('activeComponentDetails', activeComponentDetails);
    // Called when a file node is clicked.
    const handleFileOpen = async (groupName: string, path: string, componentName: string) => {
        if (!path) return;

        setIsLoading(true);
        const fileType = getFileType(path);

        try {

            const zipComponents = await getZipComponents({ source_id: sourceIdData, componentGroup: groupName, componentName, filename: path.split('/').pop() ?? '' }).unwrap();
            setActiveFile({
                url: zipComponents?.presigned_url || '',
                type: fileType,
                name: componentName,
                details: zipComponents?.component_details || []
            });
            // setRightPanelOpen(true);
        } catch (error) {
            console.error("Error handling file:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Render the content based on file type
    const renderContent = () => {
        console.log('activeFile', activeFile);
        if (isLoading) {
            return (
                <div className="flex items-center justify-center h-full">
                    <CtySpinner size="lg" />
                </div>
            );
        }

        switch (activeFile.type) {
            case 'svg':
                return <PartsSvgViewer svgUrl={activeFile.url} isFileViewer={true} currentActivePartsRowData={[activeComponentDetails]} setSelectedRowId={() => { }} activeSourceData={[]} filename={activeFile.name} />;
            case 'pdf':
            case 'image':
                return (
                    <div className="w-full h-full">
                        <div className="px-3 py-2 font-medium text-base text-black">
                            {activeFile?.name || ''}
                        </div>
                        <iframe
                            src={activeFile.url}
                            className="w-full h-[calc(100%-40px)]"
                            title={activeFile.name}
                        />
                    </div>
                );
            default:
                return (
                    <div className="flex items-center justify-center h-full text-gray-500">
                        Select a component to view
                    </div>
                );
        }
    };

    return (
        <div className="flex flex-row h-screen relative">
            {/* Left Panel: Component structure */}
            {leftPanelOpen ? (
                <div className="w-64 flex-shrink-0 border-r border-gray-200 py-4 overflow-auto relative">
                    <div className="flex mb-2 items-center justify-between px-4">
                        <h2 className="font-bold">Components</h2>
                        <button onClick={() => setLeftPanelOpen(false)} className="p-1">
                            <Icon icon="lets-icons:expand-left" width="24" height="24" style={{ color: "#000" }} />
                        </button>
                    </div>
                    <ComponentList
                        data={hierarchyData}
                        onComponentClick={handleFileOpen}
                        activeFileName={activeFile.name}
                    />
                </div>
            ) : (
                <div className="w-8 flex-shrink-0 flex items-center justify-center border-r border-gray-200">
                    <button onClick={() => setLeftPanelOpen(true)} className="p-1">
                        <Icon icon="lets-icons:expand-right" width="24" height="24" style={{ color: "#000" }} />
                    </button>
                </div>
            )}

            {/* Main Content Area */}
            <div className="flex-grow bg-white">
                {renderContent()}
            </div>

            {/* Right Panel: Component Details */}
            {rightPanelOpen && activeFile.details && activeFile.details.length > 0 && (
                <ComponentDetailsPanel
                    details={activeFile.details}
                    onClose={() => setRightPanelOpen(false)}
                    setActiveComponentDetails={setActiveComponentDetails}
                />
            )}
        </div>
    );
}

// The ComponentList recursively renders the component structure
interface ComponentListProps {
    data: ComponentGroup[];
    onComponentClick: (groupName: string, path: string, name: string) => void;
    activeFileName: string;
}

function ComponentList({ data, onComponentClick, activeFileName }: ComponentListProps) {
    return (
        <ul className="flex flex-col gap-3">
            {data.map((group) => (
                <ComponentGroupItem
                    key={group.component_name}
                    group={group}
                    onComponentClick={onComponentClick}
                    activeFileName={activeFileName}
                />
            ))}
        </ul>
    );
}

interface ComponentGroupItemProps {
    group: ComponentGroup;
    onComponentClick: (groupName: string, path: string, name: string) => void;
    activeFileName: string;
}

function ComponentGroupItem({ group, onComponentClick, activeFileName }: ComponentGroupItemProps) {
    const [expanded, setExpanded] = useState(true);

    return (
        <li>
            <div className="flex items-center justify-between pl-3">
                <span className="font-bold">{group.component_name}</span>
                <button onClick={() => setExpanded(!expanded)} className="mr-2" title={expanded ? "Collapse" : "Expand"}>
                    {expanded ? (
                        <Icon icon="lets-icons:expand-up" width="24" height="24" style={{ color: "#000" }} xlinkTitle='expand' />
                    ) : (
                        <Icon icon="lets-icons:expand-down" width="24" height="24" style={{ color: "#000" }} />
                    )}
                </button>
            </div>
            {expanded && (
                <div className="ml-4">
                    <ul className="flex flex-col gap-2 mt-2">
                        {group.components.map((component) => (
                            <button
                                key={component.name}
                                className={`mx-2 py-2 flex justify-start cursor-pointer ${component.path ? 'text-blue-600 hover:underline' : 'text-gray-400'
                                    } ${component.name === activeFileName ? 'font-bold' : ''}`}
                                onClick={() => component.path && onComponentClick(group.component_name, component.path, component.name)}
                            >
                                <span className="ml-2">
                                    {component.name}
                                </span>
                            </button>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}

export default ZipFileExplorer;