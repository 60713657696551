import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import * as d3 from 'd3';
import { useGetPdfLinkForConversationsMutation } from '@circuitry-ai/doc-data';
import { useParams } from 'react-router-dom';
import CtySpinner from '../spinner/CtySpinner';
import { CtyButton } from '../button/CtyButton';
import { Image, Tooltip } from '@nextui-org/react';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';
import { Icon } from '@iconify/react';

interface PartsSvgViewerProps {
  svgUrl: string;
  isFileViewer?: boolean;
  currentActivePartsRowData?: any;
  setSelectedRowId?: any;
  activeSourceData?: any;
  filename?: string;
}

// Loading spinner component
const LoadingSpinner = ({ loadingText }: { loadingText: string; }) => (
  <div className="flex justify-center items-center min-h-[500px]">
    <CtySpinner size="md" color="primary" />
  </div>
);
function ensureSvgOverflowVisible(svgText) {
  // Use a regex to find the <svg> tag and check for the overflow attribute
  const svgTagRegex = /<svg[^>]*>/;
  const match = svgText.match(svgTagRegex);

  if (match) {
    const svgTag = match[0];
    if (!/overflow=["']visible["']/.test(svgTag)) {
      // Add overflow="visible" to the <svg> tag
      const updatedSvgTag = svgTag.replace(
        /<svg/,
        '<svg overflow="visible"'
      );
      return svgText.replace(svgTag, updatedSvgTag);
    }
  }
  return svgText; // Return unchanged if already has overflow="visible"

}



const PartsSvgViewer: React.FC<PartsSvgViewerProps> = ({ svgUrl, isFileViewer, currentActivePartsRowData, setSelectedRowId, activeSourceData, filename }) => {
  const partsList = useMemo(() => {
    return activeSourceData?.element_refs?.[0]?.text || [];
  }, [activeSourceData]);

  const svgContainerRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const zoomRef = useRef<d3.ZoomBehavior<Element, unknown> | null>(null);
  const [tooltipData, setTooltipData] = useState<PartInfo | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number; } | null>(null);
  const [svgLoadError, setSvgLoadError] = useState<string | null>(null);
  const fileNameFromParams = useParams<{ fileName: string; }>();
  const fileName = isFileViewer ? filename : fileNameFromParams?.fileName || '';
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get the active reference number from either the row data or component details
  const activeReferenceNumber = isFileViewer
    ? currentActivePartsRowData?.[0]?.referenceNumber
    : currentActivePartsRowData?.[1]?.props?.children;

  const getSvgScreenCoordinates = (element: SVGElement): { x: number; y: number } => {
    const svgElement = svgRef.current;
    if (!svgElement || !svgContainerRef.current) return { x: 0, y: 0 };

    // Get the SVG's CTM (Current Transform Matrix)
    const ctm = element.getScreenCTM();
    if (!ctm) return { x: 0, y: 0 };

    // Get the bounding box of the element
    const bbox = element.getBBox();

    // Calculate center point of the text element in SVG coordinates
    const centerX = bbox.x + bbox.width / 2;
    const centerY = bbox.y + bbox.height / 2;

    // Transform the point to screen coordinates
    const point = svgElement.createSVGPoint();
    point.x = centerX;
    point.y = centerY;
    const screenPoint = point.matrixTransform(ctm);

    // Get the container's position
    const containerRect = svgContainerRef.current.getBoundingClientRect();

    // Calculate final coordinates relative to the container
    return {
      x: screenPoint.x - containerRect.left,
      y: screenPoint.y - containerRect.top
    };
  };

  const handleTextClick = useCallback((
    textElement: d3.Selection<Element, unknown, null, undefined>,
    part: any,
    isTableRowClicked: boolean
  ) => {
    if (!textElement.node() || !svgRef.current || !svgContainerRef.current) return;

    const coords = getSvgScreenCoordinates(textElement.node() as SVGElement);

    // Set tooltip data based on the source (file viewer or chat component)
    const toolTipData = isFileViewer ? {
      parts_number: isTableRowClicked
        ? currentActivePartsRowData?.[0]?.parts_number
        : part?.parts_number,
      description: isTableRowClicked
        ? currentActivePartsRowData?.[0]?.name
        : part?.name
    } : {
      parts_number: isTableRowClicked
        ? currentActivePartsRowData?.[2]?.props?.children
        : part?.part_info?.parts_number ?? "",
      description: isTableRowClicked
        ? currentActivePartsRowData?.[3]?.props?.children
        : part?.part_info?.description ?? "",
    };

    if (!isFileViewer && !isTableRowClicked) {
      const tableRowId =
        part.reference_number +
        "-" +
        part.part_info.parts_number +
        "-" +
        part.part_info.description;
      setSelectedRowId && setSelectedRowId(tableRowId);
    }

    setTooltipData(toolTipData);
    setTooltipPosition(coords);
  }, [currentActivePartsRowData, isFileViewer, setSelectedRowId]);

  // Function to update parts highlighting
  const updatePartsHighlighting = useCallback(() => {
    if (!svgRef.current) return;

    const svgElement = d3.select(svgRef.current);
    const textElements = svgElement.selectAll("g text");

    textElements.each(function () {
      const textElement = d3.select(this);
      const textContent = textElement.text().trim();

      // Find matching part based on reference number
      const part = isFileViewer
        ? { parts_number: currentActivePartsRowData?.[0]?.parts_number, name: currentActivePartsRowData?.[0]?.name }
        : partsList.find((p: any) => p.reference_number === textContent);

      if (part) {
        textElement
          .classed("highlighted", true)
          .on("click", () => handleTextClick(textElement, part, false));
      }
    });

    // Handle active reference number highlighting
    if (activeReferenceNumber) {
      const targetTextElement = textElements.filter((_, i, nodes) =>
        d3.select(nodes[i]).text().trim() === activeReferenceNumber
      );

      if (targetTextElement.node()) {
        const part = isFileViewer
          ? currentActivePartsRowData?.[0]
          : partsList.find((p: any) => p.reference_number === activeReferenceNumber);
        handleTextClick(targetTextElement, part, true);
      }
    }
  }, [partsList, activeReferenceNumber, currentActivePartsRowData, handleTextClick, isFileViewer]);

  const renderSvgAndTooltip = () => {
    return (
      <div className='relative'>
        <div ref={svgContainerRef} style={{ minWidth: '1000px', position: "relative", overflow: "hidden" }}></div>

        <TooltipComponent
          tooltipData={tooltipData}
          tooltipPosition={tooltipPosition}
          setTooltipData={setTooltipData}
          setSelectedRowId={setSelectedRowId}
        />

      </div>
    );
  };


  useEffect(() => {
    const loadSvg = async () => {
      setIsLoading(true);
      setSvgLoadError(null);
      if (!svgUrl) {
        setSvgLoadError("No SVG URL provided");
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(svgUrl);
        if (!response.ok) {
          throw new Error(`Failed to fetch SVG: ${response.statusText}`);
        }
        const svgText = await response.text();
        const updatedSvgText = ensureSvgOverflowVisible(svgText);

        const parser = new DOMParser();
        const svgDocument = parser.parseFromString(updatedSvgText, "image/svg+xml");
        const svgElement = svgDocument.documentElement;


        if (svgContainerRef.current) {
          svgContainerRef.current.innerHTML = "";
          svgContainerRef.current.appendChild(svgElement);

          // Disable native scroll when mouse wheel is used inside the container.
          svgContainerRef.current.addEventListener(
            "wheel",
            (e) => {
              e.preventDefault();
            },
            { passive: false }
          );
        }
        svgRef.current = svgElement;
        svgElement.setAttribute("viewBox", "-130 -20 600 594");


        const svgSelection = d3.select(svgElement);
        const zoomGroup = svgSelection.append("g");
        svgSelection.selectAll(":scope > *:not(g)")
          .each(function () {
            zoomGroup.node()?.appendChild(this);
          });
        // Also, if there are already one or more <g> elements as direct children,
        // move them into the new wrapper (this avoids double-wrapping if everything's already in one <g>)
        svgSelection.selectAll(":scope > g")
          .filter(function () {
            // Filter out the newly created wrapper so you don't move it again
            return this !== zoomGroup.node();
          })
          .each(function () {
            zoomGroup.node()?.appendChild(this);
          });


        // Apply Zoom functionality
        const zoom = d3.zoom()
          .scaleExtent([0.1, 200])
          .on("zoom", (event) => {
            zoomGroup.attr("transform", event.transform);
          });

        d3.select(svgElement).call(zoom);
        zoomRef.current = zoom;

        updatePartsHighlighting();
      } catch (error) {
        console.error("Error loading SVG:", error);
        setSvgLoadError(error instanceof Error ? error.message : "Unknown error loading SVG");
      } finally {
        setIsLoading(false);
      }
    };

    loadSvg();
  }, [svgUrl]);


  // Update highlighting whenever `currentActivePartsRowData` changes
  useEffect(() => {
    updatePartsHighlighting();
  }, [currentActivePartsRowData, updatePartsHighlighting]);

  // Zoom Controls
  const handleZoomIn = () => {
    if (svgRef.current && zoomRef.current) {
      d3.select(svgRef.current).transition().duration(200).call(zoomRef.current.scaleBy, 1.2);
    }
  };

  const handleZoomOut = () => {
    if (svgRef.current && zoomRef.current) {
      d3.select(svgRef.current).transition().duration(200).call(zoomRef.current.scaleBy, 0.8);
    }
  };
  const handleReset = () => {
    if (svgRef.current && zoomRef.current) {
      d3.select(svgRef.current)
        .transition()
        .duration(200)
        .call(zoomRef.current.transform, d3.zoomIdentity);
    }
  };
  return (
    <div className="flex flex-col h-full min-h-[400px] w-full relative">
      {fileName && (
        <div className="flex flex-wrap w-full gap-10 items-center justify-center p-4 border-b border-grey">
          <h3 className="font-bold text-center">{fileName}</h3>
        </div>
      )}
      {isLoading && <LoadingSpinner text="Preparing Svg...." />}

      <div className="relative">
        {/* Zoom Controls */}
        <div className="absolute top-4 left-4 flex gap-2 z-10">
          <div className="flex flex-col gap-2">
            <button className="p-2 bg-gray-800 text-white rounded cursor-pointer" onClick={handleZoomIn} title='Zoom In'>
              +
            </button>
            <button className="p-2 bg-gray-800 text-white rounded cursor-pointer" onClick={handleZoomOut} title='Zoom Out'>
              −
            </button>
            <button className="p-2 bg-gray-800 text-white rounded cursor-pointer" onClick={handleReset} title='Reset Zoom'>
              <Icon icon="system-uicons:reset" width="21" height="21" />
            </button>
          </div>
        </div>

        {/* SVG Container */}
        {
          svgLoadError ? (
            <div className="text-red-500 flex justify-center items-center h-[400px]">
              {svgLoadError}
            </div>
          ) : (
            renderSvgAndTooltip()
          )
        }

      </div>
    </div>
  );
};


interface PartInfo {
  parts_number: string;
  description: string;
}

interface PartSvgViewerInlineProps {
  message: any;
  advisorId: string;
  currentActivePartsRowData: any;
  setSelectedRowId: any;
}
const PartSvgViewerInline: React.FC<PartSvgViewerInlineProps> = ({
  message,
  advisorId,
  currentActivePartsRowData,
  setSelectedRowId,
}) => {
  // Get the source id data from the message. This may change with every chat response.
  const sourceIdData = message?.metaData?.[1]?.source_ids || [];

  // 1. Memoize svgFiles so that we only get the SVG files from the response
  // and recalc only when sourceIdData changes.
  const svgFiles = useMemo(
    () =>
      sourceIdData.filter((source: any) =>
        source.file_name
      ),
    [sourceIdData]
  );

  // When the incoming source IDs change, reset the active index to 0.
  useEffect(() => {
    setActiveSvgIndex(0);
  }, [svgFiles]);

  // Active index in the carousel.
  const [activeSvgIndex, setActiveSvgIndex] = useState(0);

  // 2. Cache fetched URLs keyed by the trimmed file name.
  const [svgCache, setSvgCache] = useState<{ [fileName: string]: string }>({});

  // 3. Create elementRefs for API calls based on the active SVG.
  const [elementRefs, setElementRefs] = useState<any[]>(() =>
    svgFiles.length > 0
      ? [{ source_id: svgFiles[activeSvgIndex].source_id, page_labels: [] }]
      : []
  );

  useEffect(() => {
    if (svgFiles.length > 0) {
      const newElementRef = [
        { source_id: svgFiles[activeSvgIndex].source_id, page_labels: [] },
      ];
      setElementRefs((prev) => {
        // Only update if the new element ref is different.
        return JSON.stringify(prev) !== JSON.stringify(newElementRef)
          ? newElementRef
          : prev;
      });
    }
  }, [activeSvgIndex, svgFiles]);

  const [getPdfLink, { error, isError }] = useGetPdfLinkForConversationsMutation();
  const [svgUrl, setSvgUrl] = useState<string>('');
  const [isPartsDataLoading, setIsPartsDataLoading] = useState(false);

  const imgRef = useRef<any>(null);
  const viewerRef = useRef<any>(null);

  // 4. Update the active index if an external selected file changes.
  useEffect(() => {
    const selectedRowFileName = currentActivePartsRowData?.[5]?.props?.children;
    if (selectedRowFileName) {
      const index = svgFiles.findIndex(
        (source: any) =>
          source.file_name.trim() === selectedRowFileName.trim()
      );
      if (index !== -1 && index !== activeSvgIndex) {
        setActiveSvgIndex(index);
      }
    }
  }, [currentActivePartsRowData, svgFiles, activeSvgIndex]);

  // 5. Fetch the SVG URL when the active index changes—but only if not already cached.
  useEffect(() => {
    if (svgFiles.length === 0) return;
    const activeSvg = svgFiles[activeSvgIndex];
    const activeFileName = activeSvg.file_name.trim();

    // If we already have a URL for this file, use it.
    if (svgCache[activeFileName]) {
      setSvgUrl(svgCache[activeFileName]);
      return;
    }

    // Otherwise, fetch it using the API.
    (async () => {
      try {
        setIsPartsDataLoading(true);
        const elementRef = [{ source_id: activeSvg.source_id, page_labels: [] }];
        const getPdfLinkRequestPayload = {
          advisor_id: advisorId,
          source_ids: elementRef,
        };
        const urlResponse = await getPdfLink(getPdfLinkRequestPayload).unwrap();
        const newUrl = urlResponse?.data?.[0]?.full_source_presigned_url || '';
        // Update the cache with this new URL.
        setSvgCache((prev) => ({ ...prev, [activeFileName]: newUrl }));
        setSvgUrl(newUrl);
      } catch (e) {
        console.error((e as Error)?.message || 'Error Fetching Image');
      } finally {
        setIsPartsDataLoading(false);
      }
    })();
  }, [activeSvgIndex, svgFiles, advisorId, getPdfLink, svgCache]);

  // 6. Initialize the Viewer for the image when the svgUrl is available.
  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    if (imgRef.current && svgUrl) {
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
      timerId = setTimeout(() => {
        viewerRef.current = new Viewer(imgRef.current, {
          title: false,
          focus: false,
          toolbar: {
            zoomIn: { show: 1, size: 'large' },
            zoomOut: { show: 1, size: 'large' },
            oneToOne: { show: 1, size: 'large' },
            reset: { show: 1, size: 'large' },
            prev: { show: 1, size: 'large' },
            play: { show: 4, size: 'large' },
            next: { show: 1, size: 'large' },
            rotateLeft: { show: 1, size: 'large' },
            rotateRight: { show: 1, size: 'large' },
            flipHorizontal: { show: 1, size: 'large' },
            flipVertical: { show: 1, size: 'large' },
          },
          navbar: false,
          slideOnTouch: false,
          fullscreen: false,
        });
      }, 100);
    }
    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [svgUrl]);

  // 7. Carousel control functions.
  const handlePrev = () => {
    setActiveSvgIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setActiveSvgIndex((prev) => Math.min(prev + 1, svgFiles.length - 1));
  };
  return (
    <div className="h-[100%] relative">
      {/* Carousel Navigation */}
      {svgFiles?.length > 1 && (
        <div className="absolute inset-0 flex items-center justify-between px-4 pointer-events-none z-10">
          <button
            onClick={handlePrev}
            disabled={activeSvgIndex === 0}
            className={`btn-prev pointer-events-auto ${activeSvgIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            title="Previous"
          >
            <Icon icon="mingcute:left-fill" width="24" height="24" style={{ color: 'blue' }} />
          </button>
          <button
            onClick={handleNext}
            disabled={activeSvgIndex === svgFiles.length - 1}
            className={`btn-next pointer-events-auto ${activeSvgIndex === svgFiles.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
            title="Next"
          >
            <Icon icon="mingcute:right-fill" width="24" height="24" style={{ color: 'blue' }} />
          </button>
        </div>
      )}

      {svgFiles.length > 0 ? (
        <div className="w-full relative min-w-[800px]">
          {isError ? (
            <div className="text-red-500 flex justify-center items-center h-[600px]">
              {error?.message ||
                'Failed to load SVG, please try again later.'}
            </div>
          ) : isPartsDataLoading ? (
            <LoadingSpinner text="Fetching Svg ...." />
          ) : (
            <div className="w-[100%] h-[100%]">


              {/* Conditional rendering for SVG viewer */}
              {svgFiles[activeSvgIndex].file_name.trim().endsWith('svg') ? (
                <PartsSvgViewer
                  svgUrl={svgUrl}
                  isFileViewer={false}
                  currentActivePartsRowData={currentActivePartsRowData}
                  setSelectedRowId={setSelectedRowId}
                  activeSourceData={svgFiles[activeSvgIndex]}
                />
              ) : (
                <div
                  ref={imgRef}
                  className="w-full max-w-[800px] mx-auto h-[100%]"
                >
                  <img
                    src={svgUrl}
                    alt={svgFiles[activeSvgIndex].file_name}
                    className="object-contain max-w-full h-auto cursor-pointer w-full h-[100%]"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>No SVG files available.</div>
      )}
    </div>
  );
};


interface TooltipComponentProps {
  tooltipData: PartInfo | null;
  tooltipPosition: { x: number; y: number; } | null;
  setTooltipData: (data: PartInfo | null) => void;
  setSelectedRowId?: (id: string) => void;
}
const TooltipComponent = ({ tooltipData, tooltipPosition, setTooltipData, setSelectedRowId }: TooltipComponentProps) => {
  const handleCloseTooltip = () => {
    setTooltipData(null);
    setSelectedRowId && setSelectedRowId('');

  };
  return (
    <Tooltip
      isOpen={!!tooltipData}
      content={
        <div
          style={{
            maxWidth: "200px",
            wordBreak: "break-word",
            paddingRight: "20px",
            position: "relative", // needed for absolute positioning of the close button
          }}
        >
          {/* Non-interactive text content */}
          <div style={{ pointerEvents: "none" }}>
            <div>
              <strong>Part Number:</strong> {tooltipData?.parts_number}
            </div>
            <div>
              <strong>Description:</strong> {tooltipData?.description}
            </div>
          </div>
          {/* Interactive close button */}
          <div
            style={{
              position: "absolute",
              top: "2px",
              right: "2px",
              pointerEvents: "auto",
            }}
          >
            <CtyButton
              className="cursor-pointer text-white text-xs p-1 rounded-full min-w-0 bg-transparent"
              onPress={handleCloseTooltip}
              title="Close Tooltip"
            >
              ✕
            </CtyButton>
          </div>
        </div>
      }
      placement="top"
      className="bg-[#003A79] text-white p-2 rounded-lg shadow-lg"
      isDismissable={true}
      showArrow
      color="primary"
    >
      {/* The trigger element should remain non-interactive so that events pass through */}
      <div
        style={{
          position: "absolute",
          left: `${tooltipPosition?.x}px`,
          top: `${tooltipPosition?.y}px`,
          transform: "translate(-50%, -100%)",
          zIndex: 9999,
          pointerEvents: "none",
        }}
      ></div>
    </Tooltip>
  );
};






export { PartSvgViewerInline, PartsSvgViewer };

