const apiEndPoints = {
  //advisor api's
  getAdvisors: '/api/v1/advisors',
  updateAdvisor: '/api/v1/advisors',
  createAdvisor: '/api/v1/advisors',
  updateAdvisorStatus: '/api/v1/advisors/status',
  deleteAdvisor: '/api/v1/advisors/',
  invitePalUser: '/api/v1/pal/invite',
  getPalUserDetails: '/api/v1/pal/invitees',
  uploadAdvisorLogo: '/api/v1/advisor/upload/generate',
  getadvisorprompts: '/api/v1/data/prompts',
  updateAdvisorPrompts: '/api/v1/data/prompts',

  //default adviosr api's 
  defaultAdvisor: '/api/v1/advisor/default',

  //chat-advisor api's
  updateFeedback: '/api/v1/conversations/feedback',
  expertAnswer: '/api/v1/expert',
  updateExpertAnswer: '/api/v1/expert',
  getExpertAnswerByMsgId: '/api/v1/expert',
  updateExpertAnswerStatus: '/api/v1/expert/status',

  //image generate for converse message api
  getGenerateImage: '/api/v1/conversations/images',
  //conversation api's
  getPdfLinkForConversations: '/api/v1/conversations/knowledge',
  getConversationHistory: '/api/v2/conversations',
  getConversationMessages: '/api/v1/conversations',
  getConversationHistoryMilves: '/api/v3/conversations',
  getSemanticSeachResults: '/api/v3/conversations/search',
  //dashboard api's
  getTotalConversations: '/api/v1/dashboard',
  getDashboardConversations: '/api/v2/dashboard/conversations',
  //knowledge api's
  getPdfLink: '/api/v1/knowledge/view',
  getKnowledgeAdvisorList: '/api/v1/knowledge',
  getAdvisorKnowledgeDetails: '/api/v1/knowledge',
  getPresignedUrl: '/api/v1/knowledge/upload/generate',
  uploadKnowledgeUrl: '/api/v1/knowledge',
  getKnowledgeUrlSources: '/api/v1/knowledge/url/sources',
  uploadUrlSourceList: '/api/v2/knowledge',
  delinkKnowledge: '/api/v1/knowledge',
  deleteKnowledge: '/api/v1/knowledge',
  getExpertAnswer: '/api/v1/expert',
  deleteExpertAnswer: '/api/v1/expert',
  delinkExpertAnswer: '/api/v1/expert',
  updateExpertAnswerMetaData: '/api/v1/expert',
  getAdvisorKnowledgeMetadata: '/api/v1/knowledge/metadata',
  updateAdvisorKnowledgeMetadata: '/api/v1/knowledge/metadata',
  updateAdvisorKnowledgeDatabricks: '/api/v1/knowledge',
  trainCreateAdvisorKnowledgeDatabricks: '/api/v1/knowledge',
  getKnowledgeFileTranscript: '/api/v1/knowledge/transcription',
  //settings api's
  inviteUser: '/api/v2/tenant/invite',
  getTeamMembers: '/api/v2/tenant/team',
  getDetails: '/api/v1/tenant',
  getUserProfileDetails: '/api/v1/tenant/user',

  //settings integration api's
  getGlobalSettings: '/api/v1/settings',
  updateSettings: '/api/v1/settings',

  //settings advisor api's
  getAdvisorAbilities: '/api/v1/settings',

  //speech token api's
  getSpeechToken: '/api/v1/pal/speech/token',

  //analytics api's
  getPbiAccessToken: '/api/v1/analytics/token',
  getpbiEmbedToken: '/api/v1/analytics/embed_token',
  getAnalyticsReportsList: '/api/v2/analytics/reports',
  refreshReportsList: 'https://api.powerbi.com/v1.0/myorg/groups/<workspace_ID>/datasets/<Dataset_ID>/refreshes',
  getRefreshAccessToken: '/api/v1/analytics/token',
  getAnalysticsSearch: '/api/v1/analytics_search',
  createAnalyst: '/api/v1/aides',
  postAidesUsage: '/api/v1/aides',

  //aiworker api's
  getAiWorkersByCategory: '/api/v1/aiworkers',
  getAiWorkerById: '/api/v1/aiworkers',
  updateAiWorkers: '/api/v1/aiworkers',
  createAiWorkers: '/api/v1/aiworkers',
  deleteAiWorkers: '/api/v1/aiworkers',

  //circuit api's
  circuitConnector: '/api/v1/integrations',

  //explain Answer api
  getExplainAnswer: '/api/v1/explainer',

  //knowledge article
  uploadKnowledgeArticle: '/api/v1/knowledge/article',
  getKnowledgeArticle: '/api/v1/knowledge/article',
  deleteKnowledgeArticle: '/api/v1/knowledge/article/',
  delinkKnowledgeArticle: '/api/v1/knowledge/article',

  //SSO api's
  downloadSSOSAMLFile: '/api/v1/sso/getfile',
  ssoConnectionPath: '/api/v1/sso/connection',
  getSSOConnectionDetails: '/api/v1/sso/tenant/connections',

  //API Keys 
  apiKeysList: '/api/v1/api_keys/tenant?api_type=tenant_id',
  apiKeys: '/api/v1/api_keys',

  //PDF Scanner api's
  getPDFPresignedURL: `/api/v1/knowledge/upload/generate`,
  getAgentsJSON: `/api/v1/agents/retrieve_json`,
  getPresignedURl: `/api/v1/connector/upload/generate`,
  getClaimsScore: `/api/v1/connector/claims_score`,
  uploadAgentImage: `/api/v1/aiworkers/sources/upload/generate`,
  getImageModalData: `/api/v1/integrations`,

  //agent api's 
  agentTicketCreation: '/api/v1/integrations',

  //Orgs
  getUserOrganisations: '/api/v2/tenant/user',

  //cart
  updateCartItem: '/api/v1/cart/item',
  getCartItems: '/api/v1/cart/item',
  deleteCartItem: '/api/v1/cart',
  deleteItemInCart: '/api/v1/cart/item',
  getCartCsvFile: '/api/v1/cart',

  //prompt api's
  getPromptInstructions: '/api/v1/prompts',
  updatePromptSettings: '/api/v1/prompts',

  //zip file api's
  getPartsZipComponents: '/api/v1/catalog',
  getPartsZipComponentDetails: '/api/v1/parts'

};

export default apiEndPoints;
